import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import previews from './images/preview-sebankid.png';
import customStyling from './images/custom-styling.png';
import StyleOriginsSnippet from '../../../snippets/style-origins.mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 1,
  "title": "Custom styling",
  "subtitle": "How to customize the UI in user-facing dialogs"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Some of the eID methods require additional dialogs outside of those of the actual eID service.
These may be styled and enhanced to you liking using one of the following approaches: `}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#custom-css"
        }}>{`Adding your own stylesheet`}</a>{`: if your goal is to apply basic styling, including a custom CSS file is all you need. `}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#custom-html"
        }}>{`Adding custom HTML and CSS`}</a>{`: can be used for creating more complex UIs (e.g. a layout with extra information around the Criipto login box).`}</li>
    </ol>
    <Highlight icon="info" mdxType="Highlight">
      <p><strong parentName="p">{`Note:`}</strong>{` To use custom styling with `}<a parentName="p" {...{
          "href": "/verify/guides/age-verification/"
        }}>{`Age Verification applications`}</a>{`, you need to configure your own MitID provider. `}</p>
    </Highlight>
    <h2>{`Custom CSS (adding a stylesheet)`}</h2>
    <p>{`You can add your own stylesheet from the `}<inlineCode parentName="p">{`Styling`}</inlineCode>{` -> `}<inlineCode parentName="p">{`General`}</inlineCode>{` tab of your management dashboard on `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/styling"
      }}>{`dashboard.criipto.com`}</a>{`.`}</p>
    <h3>{`Preview of the user-facing dialogs`}</h3>
    <p>{`In the `}<inlineCode parentName="p">{`General`}</inlineCode>{` tab, you can see a preview of the user-facing dialogs  - such as the ones for SE BankID:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "48.958333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABXUlEQVQoz51SXUsCQRTdP+uf8FeEGPgPDHrooYfeMsHodcWkhNSoJDd1p91153vmxIyjbooEDVzu3LP3nnvmsNFkOgEhSwi+BqM5yvW3r11mNAMtMxQF8TWsAAwHZ4WvXbjvLtysi2jxlWBd5FBKQmuF5XKB2ewDeZ75IOkKUjCsCMHVbQ/X3QeUZQklOSgtQUiKNF2BMwbBKaKzyxvcD0ZwRyrl8+NwiEajgXb7AvP53GOUC9zFT+gNRtDGwBiNwyMlR5SkGfKSekAFwk6ng1qthnq9jvF4vOm2dj9pLbRSsNbuwhMKhmjfY6H1ZmscxzhvNtFqtZAkiceMsUGZCb1qR/SL0N2rW7Isw+vbOz6TxPvinqaD8irBScIt4BS403+Zwvna7T8HZeZ/hDZkIQSYkBAVj/zwCcLtC4883CnVCoeY/hMLhNJ5GIw24VdQUlbqgKlDzBxhbolT+AO1agK4z1IN5QAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Previews",
          "title": "Previews",
          "src": "/static/89f76a756e67912183f970a05d06a987/e5715/preview-sebankid.png",
          "srcSet": ["/static/89f76a756e67912183f970a05d06a987/8514f/preview-sebankid.png 192w", "/static/89f76a756e67912183f970a05d06a987/804b2/preview-sebankid.png 384w", "/static/89f76a756e67912183f970a05d06a987/e5715/preview-sebankid.png 768w", "/static/89f76a756e67912183f970a05d06a987/4ad3a/preview-sebankid.png 1152w", "/static/89f76a756e67912183f970a05d06a987/71c1d/preview-sebankid.png 1536w", "/static/89f76a756e67912183f970a05d06a987/098c1/preview-sebankid.png 1578w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Each eID method supports a specific set of languages - see `}<a parentName="p" {...{
        "href": "/verify/guides/choose-language"
      }}>{`UI Language`}</a>{` for details. You can change the language used to generate the previews in the dropdown.`}</p>
    <p>{`Each of the previews is shown in mobile device size by default, but you can expand each of the previews to desktop size by clicking on the dashed square shown just above each preview. In desktop mode, all dialogs will have a customizable common header and footer structure, as well as the elements presented for mobile devices.`}</p>
    <p>{`You can enter a URL to your own (self-hosted) style sheet just above the previews, and experiment with the effect of changing the preview tile size. The previews use the value in the `}<inlineCode parentName="p">{`Link to your own style sheet`}</inlineCode>{` field, so you can see the effect of your customizations before you hit `}<inlineCode parentName="p">{`Save`}</inlineCode>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAlklEQVQY053OzQ7CIBAEYN7/zYweGr3oxVQoxhYLtBjL8uOo1fROD18ye5jJsu3+hE11hJASz4fBYO+zcehXYTXnOF84eCNgdAujFQbbL8Ol2K46oGkEenWFFDWs6fBwZv2H8tahVQra6Nk3O2dB3sFPYyEHFkIAEcETYfJ+FmNAzhE5FcgRKRLYUsw/r/+dUigUET+Db1zszL9m81vHAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Custom Styling",
          "title": "Custom Styling",
          "src": "/static/5f7d03a8a3db156c73473f6d9c6790b5/e5715/custom-styling.png",
          "srcSet": ["/static/5f7d03a8a3db156c73473f6d9c6790b5/8514f/custom-styling.png 192w", "/static/5f7d03a8a3db156c73473f6d9c6790b5/804b2/custom-styling.png 384w", "/static/5f7d03a8a3db156c73473f6d9c6790b5/e5715/custom-styling.png 768w", "/static/5f7d03a8a3db156c73473f6d9c6790b5/11b93/custom-styling.png 1124w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3>{`'Dynamic' style sheets`}</h3>
    <p>{`In some cases, bundling all of your CSS in just one style sheet can be tricky and/or infeasible. Criipto Verify supports overriding the `}<em parentName="p">{`path`}</em>{` part of the style sheet URL on a per-authorize request basis.`}</p>
    <p>{`You can specify a `}<inlineCode parentName="p">{`css_path:/a/b/...?c=d&e=f&...`}</inlineCode>{` token in the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` query parameter in your authorize request, which will then override the path and query parts of the pre-configured style sheet URL.
Criipto Verify will use the authority part of your pre-configured URL as a "base" URL, and then set the value you specify in the `}<inlineCode parentName="p">{`css_path`}</inlineCode>{` token as the path name and query part of the style sheet URL.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`If you have not configured a custom style sheet URL, Criipto Verify ignores the `}<inlineCode parentName="p">{`css_path`}</inlineCode>{` token in the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` query parameter.`}</p>
    </Highlight>
    <h4>{`Example`}</h4>
    <p>{`If you have configured, say,`}</p>
    <pre><code parentName="pre" {...{}}>{`https://my.css.host/static/path
`}</code></pre>
    <p>{`as a custom style sheet, and your construct an authorize request containing a`}</p>
    <pre><code parentName="pre" {...{}}>{`css_path:dynamic/subpath?q=x
`}</code></pre>
    <p>{`in the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` parameter, the resulting CSS URL put in the HTML responses from Criipto Verify will change to`}</p>
    <pre><code parentName="pre" {...{}}>{`https://my.css.host/dynamic/subpath?q=x
`}</code></pre>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`You can specify more than 1 query parameter in the `}<inlineCode parentName="p">{`css_path`}</inlineCode>{` token. In this case, you must URL-component encode (at least) the `}<inlineCode parentName="p">{`&`}</inlineCode>{` characters in the query before adding them to the value of the `}<inlineCode parentName="p">{`css_path`}</inlineCode>{` token. The URL-component version of the `}<inlineCode parentName="p">{`&`}</inlineCode>{` chararcter is `}<inlineCode parentName="p">{`%26`}</inlineCode>{`.`}</p>
    </Highlight>
    <h3>{`Common HTML structures found in user-facing dialogs`}</h3>
    <h3>{`Localizable texts`}</h3>
    <p>{`These appear in both common and dialog-specific HTML elements. The structure is the same everywhere:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`    <span class="lang lang-sv">
        <span class="text">...svenska ord...</span>
    </span>
    <span class="lang lang-en">
        <span class="text">...english words...</span>
    </span>
`}</code></pre>
    <p>{`The wording can be change via CSS selectors in your custom style sheet - see below for examples.
Depending on the eID, the `}<inlineCode parentName="p">{`lang-sv`}</inlineCode>{` class will be replaced with `}<inlineCode parentName="p">{`lang-da`}</inlineCode>{`, `}<inlineCode parentName="p">{`lang-nb`}</inlineCode>{` etc.`}</p>
    <h3>{`Desktop screen sizes`}</h3>
    <h4>{`Above the Criipto Verify frame`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<header id="header" class="default-hidden desktop-show">
    <div class="inner"></div>
</header>
<h1 id="headline" class="default-hidden desktop-show centered">
    <span class="lang lang-sv">
        <span class="text">...svenska ord...</span>
    </span>
    <span class="lang lang-en">
        <span class="text">...english words...</span>
    </span>
</h1>
`}</code></pre>
    <p>{`You can change the texts in the `}<inlineCode parentName="p">{`<h1 id="headline">`}</inlineCode>{` element via the following CSS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`#headline .lang span {
  display: none;
}
#headline .lang-sv:after {
  content: "...andra svenska ord...";
}
#headline .lang-en:after {
    content: "...other english words...";
}
`}</code></pre>
    <h4>{`Below the Criipto Verify frame`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<aside id="description" class="centered default-hidden desktop-show">
    <p>
        <span class="lang lang-sv">
            <span class="text">...svenska ord #1...</span>
        <span class="lang lang-en">
            <span class="text">...english words #1</span>
        </span>
    </p>
    <p>
        <span class="lang lang-sv">
            <span class="text">...svenska ord #2...</span>
        </span>
        <span class="lang lang-en">
            <span class="text">...english words #2</span>
        </span>
    </p>
    <p class="default-hidden">
        <span class="lang lang-sv">
            <span class="text"></span>
        </span>
        <span class="lang lang-en">
            <span class="text"></span>
        </span>
    </p>
    <p class="default-hidden">
        <span class="lang lang-sv">
            <span class="text"></span>
        </span>
        <span class="lang lang-en">
            <span class="text"></span>
        </span>
    </p>
    <p class="default-hidden">
        <span class="lang lang-sv">
            <span class="text"></span>
        </span>
        <span class="lang lang-en">
            <span class="text"></span>
        </span>
    </p>
</aside>
<footer id="footer" class="default-hidden desktop-show">
    <div class="inner centered"></div>
</footer>
`}</code></pre>
    <p><strong parentName="p"><em parentName="strong">{`Note`}</em></strong>{` The text elements in the last 3 paragraphs in the `}<inlineCode parentName="p">{`<aside>`}</inlineCode>{` are intentionally left blank by default.`}</p>
    <p>{`You can change the texts in each of the `}<inlineCode parentName="p">{`<p>`}</inlineCode>{` elements via the following CSS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`#description p:nth-child(-n+2) .lang span {
  display: none;
}
#description p:nth-child(1) .lang-sv:after {  
  content: '...andra svenska ord #1...';
}
#description p:nth-child(2) .lang-sv:after {  
  content: '...andra svenska ord #2...';
}
#description p:nth-child(1) .lang-en:after {
  content: '...other english words #1...'
}
#description p:nth-child(2) .lang-en:after {
  content: '...other english words #2...'
}
`}</code></pre>
    <p>{`where the first selector hides all the default texts in the `}<inlineCode parentName="p">{`<aside id="description">`}</inlineCode>{` element in one go, and the subsequent 4 selectors adds language-specific texts per `}<inlineCode parentName="p">{`<p>`}</inlineCode>{` element.`}</p>
    <p>{`If you want to show text in one of the hidden-by-default `}<inlineCode parentName="p">{`<p>`}</inlineCode>{` elements, you can do the following (here, the third `}<inlineCode parentName="p">{`<p>`}</inlineCode>{` is having an English text added):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`#description p:nth-child(3) {
  display: inline;
}
#description p:nth-child(3) .lang-en:after {
  content: '...this is hidden by default...'
}
`}</code></pre>
    <p><strong parentName="p"><em parentName="strong">{`Note`}</em></strong>{` The first selector is needed to "undo" the effect of the `}<inlineCode parentName="p">{`default-hidden`}</inlineCode>{` class on the third `}<inlineCode parentName="p">{`<p>`}</inlineCode>{` element.`}</p>
    <h3>{`CSS classes`}</h3>
    <h4>{`Generally available`}</h4>
    <p>{`Each `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` element has up to 5 CSS classes attached to it:`}</p>
    <ol>
      <li parentName="ol">{`Indicator of the eID method in use - such as `}<inlineCode parentName="li">{`sebankid`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Indicator that this is a page from Criipto Verify - `}<inlineCode parentName="li">{`broker`}</inlineCode>{`. This makes it possible to target our pages specifically, even if you use the same CSS file for different websites.`}</li>
      <li parentName="ol">{`Indicator of the flow in use - authentication (`}<inlineCode parentName="li">{`auth`}</inlineCode>{`) or signature (`}<inlineCode parentName="li">{`sign`}</inlineCode>{`).`}</li>
      <li parentName="ol">{`Indicator of which DNS name the page is loaded on.`}</li>
      <li parentName="ol">{`(Optional) Your very own CSS class name on a per-application basis. You can set it in the management UI as well.`}</li>
    </ol>
    <p>{`Putting it all together, for a case where:`}</p>
    <ul>
      <li parentName="ul">{`your Verify DNS name is `}<inlineCode parentName="li">{`identity.yourdomain.com`}</inlineCode>{` `}</li>
      <li parentName="ul">{`you request `}<inlineCode parentName="li">{`authentication`}</inlineCode>{` for `}<inlineCode parentName="li">{`SE BankID`}</inlineCode></li>
      <li parentName="ul">{`you have set a CSS class name value of `}<inlineCode parentName="li">{`app-one`}</inlineCode>{` for the app requesting authentication`}</li>
    </ul>
    <p>{`you will have the following CSS class content on the `}<inlineCode parentName="p">{`body`}</inlineCode>{` tag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`    <body class="sebankid broker auth host-identity-yourdomain-com app-one">
`}</code></pre>
    <p>{`You can use these dynamically added CSS classes to customize the UI on a per-application basis, which may be quite practical for, say, Single Sign-On scenarios where you re-use a Criipto Verify DNS domain across web applications with very different branding requirements.`}</p>
    <h4>{`Dialog specific`}</h4>
    <p>{`Each of the dialogs have their own HTML/CSS structure, and you can inspect the details with your browsers developer tools.
You get the best overview if you expand the preview first.`}</p>
    <h3>{`Example: Replacing headline texts in the signature flow for SE BankID`}</h3>
    <p>{`If you expand the `}<inlineCode parentName="p">{`Another Device: Prompt for SSN`}</inlineCode>{` preview, you'll se a `}<inlineCode parentName="p">{`{Log in with BankId | Logga in med BankId}`}</inlineCode>{` headline text (actual text will depend on the currently selected language for the SE BankID previews).`}</p>
    <p>{`Should you also want to change the wording for signature flow, you could add the following selectors to your custom style sheet:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.sign #headline .lang span {
  display: none;
}
.sign #headline .lang-sv:after {
  content: "Signera med Bank-ID";
}
.sign #headline .lang-en:after {
    content: "Sign with Bank-ID";
}
`}</code></pre>
    <h2>{`Custom HTML`}</h2>
    <p>{`You can create your preferred webpage structure around the login box on the Criipto login screens by adding custom HTML (with accompanying CSS). `}</p>
    <p>{`This can be done from the `}<inlineCode parentName="p">{`Styling`}</inlineCode>{` -> `}<inlineCode parentName="p">{`Custom UI`}</inlineCode>{` tab of your management dashboard on `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/styling"
      }}>{`dashboard.criipto.com`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.125%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABYlAAAWJQFJUiTwAAACDUlEQVQoz2WSy27TQBiFLYES1rHjuBILX+LcUJ+GZkfpQ0BaEAUEvBGIIMoDIMEOCZC6SNIildp4fB/fnYM8SZwGfunozIxnPp3/l7nrq19YXCzw8/wcNjHgOn/g2Ga99lxrR75HEPg2843CwK7PuGqRphE8zwGlHmjoMpVFgsB34DomXNdi8OpuELgwLQsuu+8zvzJMWDZh37inT07w5vUrvHxxiuenz3A8eYyT4wkmk0f48f0byiJDHIXI0hiUBoiiEDYxQSwDnktgGr9h2xYch8CrEjbvNCHd7UAQeQgdHny7BXGvjduNW5i+f4uq4jhGksTI8xxlWSCgCWhEQUMHxLqGtx5TNRJO2pMw3O+jN9LRv6czH+0PIHZEnH2cMiAhNnw/QFmWWC6XIOESNMmQJQHSJKyVxAG4druNbk+D0pWhdhUomgxNV9HiWzUwDCkDVbXxIk8ZIInDta/gXJWkStYdqNBHGrSeAn2ggRd4fDr7wB5fXF5iPp9jNpsxzRcLNstNqptiQH2oQ+2pkFWZiSVsbRMaJIBBQhAnBKUUURQhy5IV5EbLLKEkSegNhtD0PhRFhSzL0FQNgiDUwLJcYtXotoo8q9vcSdhoNCG2RfA8X0sURTQaDUyn79bAEv9WUWyBOwnH4wMcHT3E4eGDWtV+fHAfX798Zo+zLGWJKsjKc/ZfJrH/3wz/AiA5uDTknqvyAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Custom HTML",
          "title": "Custom HTML",
          "src": "/static/ac0c233dc5b9262e2b1321a5e8d2b7f2/e5715/custom-ui.png",
          "srcSet": ["/static/ac0c233dc5b9262e2b1321a5e8d2b7f2/8514f/custom-ui.png 192w", "/static/ac0c233dc5b9262e2b1321a5e8d2b7f2/804b2/custom-ui.png 384w", "/static/ac0c233dc5b9262e2b1321a5e8d2b7f2/e5715/custom-ui.png 768w", "/static/ac0c233dc5b9262e2b1321a5e8d2b7f2/4ad3a/custom-ui.png 1152w", "/static/ac0c233dc5b9262e2b1321a5e8d2b7f2/71c1d/custom-ui.png 1536w", "/static/ac0c233dc5b9262e2b1321a5e8d2b7f2/6e29b/custom-ui.png 1610w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3>{`Developing`}</h3>
    <p>{`You can use the built-in code editor to add your custom HTML and CSS. `}</p>
    <p>{`The preview of the user-facing dialogs will be rendered as you save your code (`}<inlineCode parentName="p">{`Save draft`}</inlineCode>{`). You can see the preview with different eIDs and screen sizes by selecting the desired values from the dropdowns: `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.479166666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAACI0lEQVQoz22PT08TQRyGe9eIB6yUUrbdbXdBbPwG+BEESgsBxKZg1GiiBzUeSAyagCbGxIvhph69efNrEDTR0BQwJpTS/TPtbukuUB6zW1qDcZI3bzLzm2eeCRnVPXZ3f7O1s42wqtSE/reFHnR7rxP/zMCum9RrRjd2vd2hzoBlGVT3yzQcgV23cGwRXOoA7boff3Yf27bQDSOIbdcwLJNKtYqoWYRev1phMpthbm6GbHaSXG6S3FSWTGaCW/mb7OyUODo6RFgN6sLFMhs0D9zgQV/EF/JFTLOKaVQIzc7OcL7nHLFElP7BCJGBPqJShEt9vchKkmJxnV9bHs/u66wulXl8R+f9GwEnDs0Dm4ZTO/2BGSS0uFAg3BdGHU6RVGUUVSalJUkocTRtiFJpg61Nj6VHFi+eGtybNXn70joFOniuw6HXCNpPaGGhwOX+MENXVVLDCoomk9RkJHmQVEoNgNtFj4cFgyd3Bflxg3crFtA2dJtnc2rYG5gNJiSkuERCjhOTYl1g6adHIaPzYN4MgKtL5hlgxy4wvL1YIBzp58pImhE1iZJIIEkSA9EBNFWjuLmBXjni41qNz59sPqw5fP1i0zp2ulZngPn8PBd6LpIauUZ6eIi0qhCXJGLRKIos8/3bOnBCq+UCLdrrGO8fUBe4vPyc66OjTGQyTE9PMTOVI5fNcmNsjPHxMYrFHwFACMFeuUylUsFtHgSX3f9A/wBnllwByI2nngAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Custom HTML Preview",
          "title": "Custom HTML Preview",
          "src": "/static/8cb31ff8311e33653fc5c93ab9c66ed5/e5715/custom-ui-preview.png",
          "srcSet": ["/static/8cb31ff8311e33653fc5c93ab9c66ed5/8514f/custom-ui-preview.png 192w", "/static/8cb31ff8311e33653fc5c93ab9c66ed5/804b2/custom-ui-preview.png 384w", "/static/8cb31ff8311e33653fc5c93ab9c66ed5/e5715/custom-ui-preview.png 768w", "/static/8cb31ff8311e33653fc5c93ab9c66ed5/4ad3a/custom-ui-preview.png 1152w", "/static/8cb31ff8311e33653fc5c93ab9c66ed5/71c1d/custom-ui-preview.png 1536w", "/static/8cb31ff8311e33653fc5c93ab9c66ed5/573d3/custom-ui-preview.png 1650w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`Your HTML must always include the `}<inlineCode parentName="p">{`<criipto-verify />`}</inlineCode>{` element, as this will define the position of the Criipto login box on the login screen.`}</p>

    <StyleOriginsSnippet mdxType="StyleOriginsSnippet" />
    <h3>{`Publishing`}</h3>
    <p>{`You can publish your draft code when ready by clicking on `}<inlineCode parentName="p">{`Publish to test`}</inlineCode>{` / `}<inlineCode parentName="p">{`Publish to produciton`}</inlineCode>{`. This will make it immediately active on logins running within the environment you're developing in.`}</p>
    <p>{`After publishing, if facing issues or otherwise, a published version can be rolled back or completely unpublished to revert the UI to its original state. `}</p>
    <h3>{`UI examples`}</h3>
    <p>{`For inspiration, take a look at various examples of custom UIs `}<a parentName="p" {...{
        "href": "/verify/articles/custom-ui-samples"
      }}>{`here`}</a>{`. `}</p>
    <h3>{`Feedback`}</h3>
    <p>{`We're working on adding differnt versions of the custom HTML depending on the selected eID.  `}</p>
    <p>{`Your feedback on this feature is highly appreciated. Don't hesitate to react out via `}<a parentName="p" {...{
        "href": "https://tiny.cc/criipto-slack"
      }}>{`Slack`}</a>{` or `}<a parentName="p" {...{
        "href": "mailto:support@criipto.com"
      }}>{`email`}</a>{` with any suggestions or comments.`}</p>
    <h3>{`Internationalization and localization`}</h3>
    <p>{`A custom `}<inlineCode parentName="p">{`<i18n>`}</inlineCode>{` tag is available, to help provide language-specific texts in the custom HTML.`}</p>
    <p>{`The language is selected based on `}<a parentName="p" {...{
        "href": "/verify/guides/choose-language/"
      }}>{`either ui_locales or Accept-Language`}</a>{`
and supports `}<inlineCode parentName="p">{`da`}</inlineCode>{`, `}<inlineCode parentName="p">{`en`}</inlineCode>{`, `}<inlineCode parentName="p">{`sv`}</inlineCode>{`, `}<inlineCode parentName="p">{`se`}</inlineCode>{`, `}<inlineCode parentName="p">{`fi`}</inlineCode>{`, `}<inlineCode parentName="p">{`nb`}</inlineCode>{` and a `}<inlineCode parentName="p">{`default`}</inlineCode>{` parameter when no language is matched.`}</p>
    <p>{`For example, if we wanted to provide a danish text, but fall back to english otherwise, we could use the `}<inlineCode parentName="p">{`i18n`}</inlineCode>{` tag as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<i18n
  da="Ved at logge ind accepterer du vores vilkår for brug"
  default="By logging in you accept our terms of use"
/>
`}</code></pre>
    <p>{`If the browser is set to danish, or you explicitely use `}<inlineCode parentName="p">{`ui_locales=da`}</inlineCode>{`, the danish text will render - otherwise the default (english).`}</p>
    <p>{`Full example of possible attributes:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<i18n
  da="[danish text]"
  en="[english text]"
  sv="[swedish text]"
  se="[swedish text]"
  fi="[finnish text]"
  nb="[norwegian text]"
  default="[fallback text]"
/>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      